import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'fbr-loader',
  standalone: true,
  imports: [
    MatProgressSpinner
  ],
  template: `<mat-spinner diameter="150"></mat-spinner>`,
  styles: `
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      &.fixed {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
      }
      &.backdrop {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  `,
  styleUrl: './loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {

  @Input() fixed: boolean = true;
  @Input() backdrop: boolean = true;

  @HostBinding('class.fixed') get fixedClass() {
    return this.fixed;
  }

  @HostBinding('class.backdrop') get backdropClass() {
    return this.backdrop;
  }

}

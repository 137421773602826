import {afterNextRender, computed, inject, Inject, Injectable, PLATFORM_ID, signal, Signal, WritableSignal} from '@angular/core';
import {FbrUserModel} from '../models/user.model';
import {isPlatformBrowser} from '@angular/common';
import {GoogleAnalyticsService} from '../services/google-analytics.service'
import {firstValueFrom, lastValueFrom, Observable, timer} from 'rxjs';
import {environment} from '../../environments/environment';
import {UserService} from '../services/user.service';
import {LanguageService} from '../services/language.service';
import {UserSettingsStore} from '../modules/user-settings-modal/user-settings.store';

export type UserInitType = 'not-init'| 'in-progress' | 'init' | 'error';

interface UserStoreI {
  inited: UserInitType;
  fbrUser: FbrUserModel;
}

@Injectable({
  providedIn: 'root'
})
export class UserStore {
  //initied true before ssr auth added
  public state: WritableSignal<UserStoreI> = signal({
    fbrUser: null,
    inited: null
  });
  currentUser: Signal<FbrUserModel> = computed(() => this.state().fbrUser);
  inited: Signal<UserInitType> = computed(() => this.state().inited);
  userPro: Signal<boolean> = computed(() => this.state().fbrUser?.isPro);
  userService = inject(UserService);

  constructor(
    @Inject(PLATFORM_ID) public platformId: string,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly userSettingsStore: UserSettingsStore
  ) {
  }

  public login() {
    window.location.href = `${environment.apiHost2}/auth/cognito`;
  }

  public logout() {
    window.location.href = `${environment.apiHost2}/auth/cognito/logout`;
  }

  public updateUser(): Promise<void> {
    return this.userService.getUser()
      .then((user) => this.setUser(user))
      .catch((e) => {
        throw e;
      });
  }

  public getUser() {
    this.setInited('in-progress');
    return this.userService.getUser()
      .then((user) => this.setUser(user))
      .catch((e) => {
        this.setInited('error');
        throw e;
      });
  }

  public setUser(user): void {
    if (user) {
      this.googleAnalyticsService.sendLoginEvent(user);
      this.userSettingsStore.init();
      this.state.set({
        ...this.state(),
        fbrUser: user,
        inited: 'init'
      });
    }
  }

  public setInited(inited: UserInitType) {
    this.state.update((data) => ({
      ...data,
      inited: inited
    }));
  }
}

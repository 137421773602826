import { Injectable } from '@angular/core';
import {TRangePages} from '../../pages/ranges/enums/range-pages.enum';
import {FbrUserModel} from '../models/user.model';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }


  public sendEvent(
    eventName: string,
    eventCategory: string){
    gtag('event', eventName, {
      event_category: eventCategory
    })
  }

  public sendLoginEvent(user: FbrUserModel){
    gtag('event', 'userLogin', {
      id: user.id,
      email: user.email
    });
  }


  public sendOpenProModalEvent() {
    this.sendEvent(
      'Open PRO modal',
      'Subscription',
    );
  }

  public sendActivateTrialModalEvent() {
    this.sendEvent(
      'Activate trial',
      'Subscription'
    );
  }

  public sendBuyFromProModalEvent() {
    this.sendEvent(
      'Click Buy from PRO modal',
      'Subscription'
    );
  }

  public sendCheckoutBuyModalEvent() {
    this.sendEvent('Click Checkout from Buy modal', 'Subscription')
  }

  public sendClickOpenConfigurationModalEvent() {
    this.sendEvent('Open Import configuration modal','Features using')
  }


  public sendClickImportConfigurationEvent() {
    this.sendEvent('Click Import configuration','Features using')
  }

  public sendClickExportConfigurationEvent() {
    this.sendEvent('Click Export configuration','Features using')
  }

  public sendSwitchToPage(page: TRangePages) {
    this.sendEvent(`Switch to ${page}`,'Features using')
  }

  public sendShareModalOpenEvent() {
    this.sendEvent('Share modal open','Features using')
  }

  public sendShareScreenshotEvent() {
    this.sendEvent('Share screenshot','Features using')
  }

  public sendNewTrainingEvent() {
    this.sendEvent('New Training', 'Trainer')
  }

  public sendEditTrainingEvent() {
    this.sendEvent('Edit Training', 'Trainer')
  }

  public sendStartTrainingEvent() {
    this.sendEvent('Start Training', 'Trainer')
  }
}
